import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectLanguage } from "../redux/language/languageSelectors";
import getTranslatedPath from "../helpers/getTranslatedPath";

const SharedLayout = lazy(() =>
  import("./commonComponents/sharedLayout/SharedLayout")
);
const Main = lazy(() => import("../pages/main/main"));
const AboutUs = lazy(() => import("../pages/aboutUs/aboutUs"));
const Projects = lazy(() => import("../pages/projects/projects"));
const ProjectPage = lazy(() => import("../pages/projectPage/projectPage"));
const Vacansies = lazy(() => import("../pages/vacansies/vacansies"));
const VacancyPage = lazy(() => import("../pages/vacancyPage/vacancyPage"));
const News = lazy(() => import("../pages/news/news"));
const NewsPage = lazy(() => import("../pages/newsPage/newsPage"));
const Analytics = lazy(() => import("../pages/analytics/analytics"));
const Services = lazy(() => import("../pages/Services/Services"));
const Politics = lazy(() => import("../pages/politics/politics"));
const Directory = lazy(() => import("../pages/directory/directory"));
// const UnderDevelopment = lazy(() =>
//   import("../pages/underDevelopment/underDevelopment")
// );
const NotFound = lazy(() => import("../pages/notFound/NotFound"));
const Login = lazy(() => import("../pages/adminPages/login/login"));

function App() {
  const lang = useSelector(selectLanguage);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    const langFromLocation =
      location.pathname.split("/")[1] === "en" ? "en" : "ua";

    if (langFromLocation === "ua" || langFromLocation === "en") {
      if (langFromLocation !== lang) {
        const translatedPath = getTranslatedPath(location.pathname, lang);
        navigate(translatedPath, { replace: true });
      }
    }
  }, [dispatch, lang, i18n, location.pathname, navigate]);

  return (
    <Routes>
      {/* <Route path="/" element={<SharedLayout />}>
        <Route index element={<Main />} />
        <Route path="*" element={<NotFound />} />
      </Route> */}
      <Route path="/" element={<SharedLayout />}>
        <Route index element={<Main />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="projects" element={<Projects />} />
        <Route path="projects/:id" element={<ProjectPage />} />
        <Route path="career" element={<Vacansies />} />
        <Route path="career/:id" element={<VacancyPage />} />
        <Route path="news" element={<News />} />
        <Route path="news/:id" element={<NewsPage />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="eservices" element={<Services />} />
        <Route path="directory" element={<Directory />} />
        <Route path="privacypolicy" element={<Politics />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/en" element={<SharedLayout />}>
        <Route index element={<Main />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="projects" element={<Projects />} />
        <Route path="projects/:id" element={<ProjectPage />} />
        <Route path="career" element={<Vacansies />} />
        <Route path="career/:id" element={<VacancyPage />} />
        <Route path="news" element={<News />} />
        <Route path="news/:id" element={<NewsPage />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="eservices" element={<Services />} />
        <Route path="directory" element={<Directory />} />
        <Route path="privacypolicy" element={<Politics />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* <Route path="/login" element={<Login />} /> */}

      {/* <Route path="/adminpage" element={<AdminSharedLayout />}></Route> */}
    </Routes>
  );
}

const WrappedApp = () => {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
};

export default WrappedApp;
